import { AnimatePresence, motion, useCycle } from "framer-motion";
import { MenuItem, NavHeader } from "./components";
import { navRoutes } from "@routes";
import bigtree from "@assets/big-tree.svg";
import Heatmap from "../Heatmap";
import { heatmaps } from "@helpers/constants";

export default function Header() {
  const [isOpen, cycleOpen] = useCycle(false, true);

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };
  
  const headerProps = { isOpen, cycleOpen };


  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.aside
            initial={{ width: 0 }}
            animate={{
              width: "100%",
              height: "100vh",
            }}
            exit={{
              width: 0,
              transition: { delay: 0.7, duration: 0.3 },
            }}
          >
            <div className="w-full flex z-40 h-screen fixed">
              <div className="w-1/2 hidden md:flex -ml-8">
                <Heatmap link={heatmaps[2]} />
                <img src={bigtree} className="pl-4 top-0 w-1/2 absolute z-40 object-cover h-full" />
              </div>
              <motion.div
                className="md:w-1/2 w-full h-full flex flex-col justify-center bg-[white]"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {navRoutes.map((item) => {
                  const itemProps = { ...item, onClick: cycleOpen };
                  return <MenuItem {...itemProps} key={item.display} />;
                })}
              </motion.div>
            </div>
          </motion.aside>
        )}
      </AnimatePresence>
      <NavHeader {...headerProps} />
    </>
  );
}