import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter, useLocation } from "react-router-dom";
import Routes, { routesFlat } from "@routes";
import { NavMenu, Footer, StayConnected } from "./components";
import reportWebVitals from "./reportWebVitals";
import { ScrollToTop } from "@helpers";
import { tags } from "@helpers/constants";
import "./index.css";
import "./pages/products/pages/JMRV/Map/map.css";
import "react-loading-skeleton/dist/skeleton.css";

import { Auth0Provider } from "@auth0/auth0-react";

ReactGA.initialize("G-KTSEKJ0CT0");

export const CMSContext = createContext(null);
export const NewsContext = createContext(null);

const root = ReactDOM.createRoot(document.getElementById("root"));

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider

const onRedirectCallback = (appState) => {
    window.history.replaceState(
        {},
        document.title,
        appState?.returnTo || window.location.pathname,
    );
};

const providerConfig = {
    domain: process.env.REACT_APP_DOMAIN,
    clientId: process.env.REACT_APP_CLIENT_ID,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
    },
};

root.render(
    <Auth0Provider {...providerConfig}>
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    </Auth0Provider>,
);

function Root() {
    const [state, setState] = useState(undefined);
    const [articles, setArticles] = useState([]);
    const [newsFilter, setNewsFilter] = useState({
        product: tags.product.none,
        type: tags.type.none,
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/newsfeed/summary`)
            .then((resp) => resp.json())
            .then((arr) => {
                if (arr.length) {
                    setArticles(arr);
                }
            });
    }, []);

    const location = useLocation();
    const path = location.pathname;

    const privacyOrTerms =
        path === routesFlat.privacy || path === routesFlat.terms;
    const contactPage = path === routesFlat.contact;
    const cmsPage = path === routesFlat.admin;

    const notLuca =
        path !== routesFlat.lucaMap && path !== routesFlat.directLucaMap;
    const notJmrv =
        path !== routesFlat.jmrvMap && path !== routesFlat.directJMRVMap;

    const displayFooter = notLuca && notJmrv && !cmsPage;
    const displayConnected = displayFooter && !privacyOrTerms && !contactPage && !cmsPage;

    const desktop = "md:mx-4 md:border-black/[10%] md:border-x-[1px]";

    return (
        <CMSContext.Provider value={{ state, setState }}>
            <div className="h-full w-full p-0 font-body">
                <div className={desktop}>
                    <ScrollToTop />
                    <NavMenu />
                    <NewsContext.Provider
                        value={{ articles, newsFilter, setNewsFilter }}
                    >
                        <Routes />
                    </NewsContext.Provider>
                    {displayConnected && <StayConnected />}
                    {displayFooter && <Footer />}
                </div>
            </div>
        </CMSContext.Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
