import { useContext } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { CMSContext } from "@";
import { routesFlat } from "../../routes";

export default function CMS() {
  const { state } = useContext(CMSContext);

  if (state?.isAdmin) {
    return (
      <iframe
        className="h-navscreen w-full"
        src={`${process.env.REACT_APP_CMS_URL}/admin#${state?.token ? state.token : ""}`}
      />
    );
  } else {
    return (
      <div className={errParentStyle}>
        <div>Error: You don't have access here.</div>
        <Link to={routesFlat.landing} className={errLinkStyle}>
          Go Home
        </Link>
      </div>
    );
  }
}

const errParentStyle = clsx(
  "w-full h-screen flex justify-center items-center flex-col",
);
const errLinkStyle = clsx(
  "bg-green px-4 py-2 rounded-md hover:bg-lightGreen mt-4",
);
