import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CMSContext } from "@";
import CtreesLogo from "../../assets/ctrees-sm-logo.png";
import { getArticles } from "./helpers";

const Login = ({ children }) => {
    const {
        isAuthenticated,
        user,
        getAccessTokenSilently,
        loginWithRedirect,
        getIdTokenClaims,
    } = useAuth0();
    const { pathname } = window.location;
    const { setState } = useContext(CMSContext);

    useEffect(() => {
        const storeToken = async () => {
            if (isAuthenticated && user) {
                try {
                    // Check to see if user exists
                    const idTokenClaims = await getIdTokenClaims();
                    const token = idTokenClaims.__raw; // Assuming profile is in JWT format
                    localStorage.setItem("token", token);

                    // Gets CMS articles using token to see if user has admin permissions
                    let isAdmin = false;
                    if (token) {
                        const checkPermissions = await getArticles(token);
                        isAdmin = !!checkPermissions;

                        localStorage.setItem("isAdmin", isAdmin);
                    }

                    setState({ token, isAdmin });
                } catch (error) {
                    console.error("Error storing token:", error);
                }
            }
        };

        storeToken();
    }, [isAuthenticated, user, getAccessTokenSilently]);

    const handleLoginClick = () => {
        loginWithRedirect({
            appState: { returnTo: pathname }, // Save current pathname in appState
        });
    };

    if (isAuthenticated) {
        return children;
    } else {
        return (
            <>
                <div className="blur-sm">{children}</div>

                <div className={promptToLogin}>
                    <div className={messageContainer}>
                        <img src={CtreesLogo} />
                        <button
                            className={buttonToLogin}
                            onClick={handleLoginClick}
                        >
                            Click here to login.
                        </button>
                        <p className="text-sm">
                            You will be redirected to our secure login page.
                            After logging in, you'll be automatically redirected
                            back to continue where you left off.
                        </p>
                    </div>
                </div>
            </>
        );
    }
};

const promptToLogin =
    "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50";
const messageContainer =
    "flex flex-col justify-center items-center w-96 space-y-4 rounded-md bg-white p-6 shadow-md";
const buttonToLogin =
    "bg-black hover:bg-gray text-white font-bold py-2 px-4 rounded";

export default Login;
