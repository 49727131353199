import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

export const Logout = () => {
    const { logout } = useAuth0();
    const returnToUrl = process.env.REACT_APP_HOME_URL || "https://ctrees.org";
    const handleLogout = () => {
        console.log(
            "Logging out, redirecting to:",
            process.env.REACT_APP_HOME_URL,
        );
        
        localStorage.clear();
        logout({
            returnTo: returnToUrl,
        });
    };

    return (
        <button onClick={handleLogout} className={btnStyle}>
            Log Out
        </button>
    );
};

const btnStyle = clsx(
    "border-2 border-green hover:border-blue mt-2 rounded-md w-[100px] text-black",
);
