import { useEffect, useState } from "react";
import OpacityWrapper from "../OpacityWrapper";
import { PrimaryBtn } from "../Button";
import { signUp } from "@helpers";
import { heatmaps } from "@helpers/constants";
import Heatmap from "../Heatmap";
import * as styles from './styles'

const btn = {
    link: "",
    className: "w-full",
};

function validEmail(email) {
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return email?.match(isValidEmail);
}

export default function StayConnected() {
    const [email, setEmail] = useState("");
    const [enabled, setEnabled] = useState(false);
    const [displayMsg, setDisplayMsg] = useState();
    const [checkbox, setCheckbox] = useState(false);

    const onSetEmail = (e) => {
        const input = e.target.value;
        const valid = validEmail(input);

        setEmail(input);

        if (valid && checkbox) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    };

    const onCheckbox = () => {
        const valid = validEmail(email);
        if (valid && !checkbox) {
            setEnabled(true);
        }

        if (checkbox) {
            setEnabled(false);
        }

        setCheckbox(!checkbox);
    };

    const onClick = (e) => signUp(e, email, setDisplayMsg);

    useEffect(() => {
        if (displayMsg) {
            setTimeout(() => {
                setEmail("");
                setDisplayMsg(null);
            }, 7000);
        }
    }, [displayMsg]);

    const btnProps = { ...btn, onClick, enabled };

    return (
        <OpacityWrapper className={styles.parentContainer}>
            <div className={styles.heatmap}>
                <Heatmap link={heatmaps[1]} />
            </div>

            <div className={styles.bodyContainer}>
                <div className={styles.innerBodyContainer}>
                    <div className="text-center text-4xl font-extrabold">
                        Stay Connected
                    </div>
                    <div className={styles.signUpText}>
                        Sign up for product updates and news from the CTrees
                        team.
                    </div>

                    <div className="relative flex w-full flex-col">
                        <div className={styles.alertContainer}>
                            {displayMsg && (
                                <>
                                    <div className={styles.displayMsgText}>
                                        {displayMsg}
                                    </div>
                                    <div className={styles.displayMsgLine}></div>
                                </>
                            )}
                        </div>
                        <input
                            required
                            type="email"
                            name="EMAIL"
                            id="mce-EMAIL"
                            className={styles.input + " required email"}
                            placeholder="Enter your email"
                            value={email}
                            onChange={onSetEmail}
                        />
                    </div>
                    <div className={styles.checkbox}>
                        <input
                            required
                            type="checkbox"
                            checked={checkbox}
                            onChange={onCheckbox}
                            className="mr-4"
                        />
                        <div>
                            By submitting your email address, you agree to
                            receive communications from CTrees. You can opt-out
                            at any time.
                        </div>
                    </div>

                    <div className="mt-10 w-full">
                        <PrimaryBtn {...btnProps}>send</PrimaryBtn>
                    </div>
                </div>
            </div>
        </OpacityWrapper>
    );
}
