import clsx from "clsx";

export const displayMsgText = clsx("rounded-lg bg-green px-2 py-1");
export const displayMsgLine = clsx(
    "w-[0px] border-t-[10px] border-x-[15px] border-t-green border-x-[transparent] ml-4",
);
export const checkbox = clsx(
    "mb-10 flex flex-nowrap items-start justify-start text-xs",
);
export const input = clsx(
    "focus:outline-none focus:ring-b focus:ring-blue w-full border-t-0 border-x-0 border-b-2" +
        " placeholder-slate-400 border-[1px] mb-4 contrast-more:placeholder-slate-500 py-3 px-4",
);
export const heatmap = clsx(
    "md:w-1/2 md:p-0 md:mt-0 md:px-0 " +
        " h-full w-auto mt-16 relative overflow-hidden",
);
export const signUpText = clsx(
    "tracking-1 mb-20 mt-10 text-center text-xl font-bold md:mb-40",
);

export const parentContainer = clsx(
    "w-full md:h-screen text-black flex flex-col md:flex-row justify-center items-center border-t-[1px] border-black/[10%]",
);
export const bodyContainer = clsx(
    "relative flex h-full w-full justify-center px-10 py-20 md:mb-0 md:w-1/2 md:px-0 md:py-0",
);
export const innerBodyContainer = clsx(
    "flex h-full flex-col items-center justify-center md:max-w-[400px]",
);
export const alertContainer = clsx(
    "absolute top-0 -mt-10 mb-4 flex h-[50px] flex-col font-bold text-white",
);
