import { useEffect, useState } from "react";
import clsx from "clsx";

export default function Heatmap({ link }) {
    const [isAutoplay, setIsAutoplay] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const isMobileOrTablet = screenWidth < 1024;
            setIsAutoplay(!isMobileOrTablet);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <video
            loading="lazy"
            className={videoStyle}
            preload="none"
            controls={false}
            loop={true}
            autoPlay={isAutoplay}
            muted={isAutoplay}
            playsInline
        >
            <source src={link} type="video/mp4" />
        </video>
    );
}

const videoStyle = clsx("h-full w-[99.7%] object-cover");
