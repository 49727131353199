export function getArticles(newToken) {
    return fetch(`${process.env.REACT_APP_API_URL}/cms/${process.env.REACT_APP_API_VERSION}/newsfeed/summary`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${newToken}`,
        },
    })
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp.error) {
                return false;
            }

            return resp;
        })
        .catch((err) => false);
}