import { useContext } from "react";
import { NewsContext } from "@";
import { fullWidthSubHeader } from "..";
import { ArticleItem } from "../../news";

export default function Latest() {
  const { articles } = useContext(NewsContext);

  // TEMPORRARY SOLUTION TO REMOVE "IFMAN Congo release" (id: 43):
  const mostRecent4 = articles.slice(0, 4);
  const removeCongoRelease = mostRecent4.filter((article) => article.id != 43)
  const latest = removeCongoRelease.slice(0, 3);

  //ORIGINAL CODE NEEDED FOR REVERTING:
  // const latest = articles.slice(0, 3);

  return (
    <>
      <div className={fullWidthSubHeader + " text-4xl w-full"}>Latest</div>
      <div className="flex flex-wrap flex-col md:flex-row my-10 justify-around w-full">
        {latest?.map((item) => (
          <ArticleItem item={item} key={item.id} />
        ))}
      </div>
    </>
  );
}
