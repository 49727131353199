import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { MenuToggle, Logout } from "../components";
import { routesFlat } from "@routes";
import { logos } from "@helpers/constants";
import clsx from "clsx";

export function NavHeader({ isOpen, cycleOpen }) {
    const { isAuthenticated } = useAuth0();
    const isOpenStyle = isOpen
        ? "fixed md:right-14 right-12"
        : "absolute md:right-0 right-12";
    const displayLogout = isAuthenticated && !isOpen;
    return (
        <div className={headerParentStyle}>
            <Link
                to={routesFlat.landing}
                className={`ml-4 flex ${isOpen ? "fixed md:hidden" : ""}`}
            >
                <img
                    src={logos.withText}
                    alt="ctrees logo with heatmap colors"
                    className={logoStyle}
                />
            </Link>
            <div className="flex w-[200px]">
                {displayLogout && <Logout />}
                <div className={`z-60 mr-10 ${isOpenStyle}`}>
                    <MenuToggle toggle={cycleOpen} isOpen={isOpen} />
                </div>
            </div>
        </div>
    );
}

const headerParentStyle = clsx(
    "flex justify-between w-full md:w-[95%] top-0 md:mt-2 mt-4 z-40 absolute",
);
const logoStyle = clsx("md:h-[50px] h-[40px] w-auto self-center");
